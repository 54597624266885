import { ChangeGuardianApprovalService } from '@amzn/change-guardian-approval-service-type-script-client';
import { AWSError } from 'aws-sdk';
import { PromiseResult } from 'aws-sdk/lib/request';
import { useContext } from 'react';
import { InfiniteData, useInfiniteQuery } from 'react-query';
import { ChangeGuardianClientContext, defaultQueryRetry } from '../../../client/ChangeGuardianClientProvider';

export default (
  reviewId: string,
  maxResults: number | undefined,
  resourceId?: string,
  onSuccess?: (
    data: InfiniteData<PromiseResult<ChangeGuardianApprovalService.ListRisksByReviewOutput, AWSError>>
  ) => void,
  onError?: (err: AWSError) => void
) => {
  const client = useContext(ChangeGuardianClientContext);
  return useInfiniteQuery(
    ['listRisksByReview', reviewId, maxResults],
    ({ pageParam = undefined }) => {
      return client
        ?.listRisksByReview({
          reviewId,
          maxResults,
          nextToken: pageParam as string,
          resourceId
        })
        .promise();
    },
    {
      onSuccess,
      onError,
      getNextPageParam: (result) => result?.nextToken,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: defaultQueryRetry
    }
  );
};
