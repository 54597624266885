import { TopNavigation } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HEADER_ID } from './constants';
import { bugReportURL, featureRequestURL, feedbackURL } from '../../common/ticketing';

const PageHeader = () => {
  const { t } = useTranslation();

  return (
    <TopNavigation
      id={HEADER_ID}
      identity={{
        href: '#',
        title: 'Change Guardian'
      }}
      i18nStrings={{
        searchIconAriaLabel: t('Search'),
        searchDismissIconAriaLabel: t('Close search'),
        overflowMenuTriggerText: t('More'),
        overflowMenuTitleText: t('All'),
        overflowMenuBackIconAriaLabel: t('Back'),
        overflowMenuDismissIconAriaLabel: t('Close menu')
      }}
      utilities={[
        {
          type: 'button',
          text: 'Help us improve Change Guardian',
          href: 'https://survey.fieldsense.whs.amazon.dev/survey/dfe6ee51-c771-47ef-9d01-4bcd19ccbcb3',
          external: true,
          externalIconAriaLabel: '(opens survey in a new tab)',
          variant: 'primary-button'
        },
        {
          type: 'menu-dropdown',
          text: 'Help',
          items: [
            {
              id: 'documentation',
              text: 'Documentation',
              href: 'https://w.amazon.com/bin/view/ChangeGuardian/',
              external: true,
              externalIconAriaLabel: '(opens in new tab)'
            },
            {
              id: 'contact-group',
              text: 'Contact Us',
              items: [
                {
                  id: 'bug',
                  text: 'Report a Bug',
                  href: bugReportURL.href,
                  external: true,
                  externalIconAriaLabel: '(opens in new tab)'
                },
                {
                  id: 'feature-request',
                  text: 'Feature Request',
                  href: featureRequestURL.href,
                  external: true,
                  externalIconAriaLabel: '(opens in new tab)'
                },
                {
                  id: 'feedback',
                  text: 'Feedback',
                  href: feedbackURL.href,
                  external: true,
                  externalIconAriaLabel: '(opens in new tab)'
                }
              ]
            }
          ]
        }
      ]}
    />
  );
};

export default PageHeader;
