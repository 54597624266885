import {
  Box,
  Button,
  ButtonProps,
  ExpandableSection,
  Header,
  Link,
  SpaceBetween,
  StatusIndicator
} from '@amzn/awsui-components-react';
import { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import React from 'react';
import i18n from '../i18n';
import { CollectionActions } from '@awsui/collection-hooks/dist/mjs/interfaces';
import { RiskStatus } from './RiskStatus';

export const CLEAR_ALL_BUTTON_TEXT = i18n.t('Clear all filters');

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: i18n.t('your choice'),
  dismissAriaLabel: i18n.t('Dismiss'),
  filteringPlaceholder: i18n.t('Search or Filter'),
  groupValuesText: i18n.t('Values'),
  groupPropertiesText: i18n.t('Properties'),
  operatorsText: i18n.t('Operators'),
  operationAndText: i18n.t('and'),
  operationOrText: i18n.t('or'),
  operatorLessText: i18n.t('Less than'),
  operatorLessOrEqualText: i18n.t('Less than or equal'),
  operatorGreaterText: i18n.t('Greater than'),
  operatorGreaterOrEqualText: i18n.t('Greater than or equal'),
  operatorContainsText: i18n.t('Contains'),
  operatorDoesNotContainText: i18n.t('Does not contain'),
  operatorEqualsText: i18n.t('Equals'),
  operatorDoesNotEqualText: i18n.t('Does not equal'),
  editTokenHeader: i18n.t('Edit filter'),
  propertyText: i18n.t('Property'),
  operatorText: i18n.t('Operator'),
  valueText: i18n.t('Value'),
  cancelActionText: i18n.t('Cancel'),
  applyActionText: i18n.t('Apply'),
  allPropertiesLabel: i18n.t('All properties'),
  tokenLimitShowMore: i18n.t('Show more'),
  tokenLimitShowFewer: i18n.t('Show fewer'),
  clearFiltersText: i18n.t('Clear filters'),
  removeTokenButtonAriaLabel: () => i18n.t('Remove token'),
  enteredTextLabel: (text: string) => i18n.t(`Use: "${text}"`)
};

export const paginationLabels = {
  nextPageLabel: i18n.t('Next page'),
  previousPageLabel: i18n.t('Previous page'),
  pageLabel: (pageNumber: number) => i18n.t(`Page ${pageNumber} of all pages`)
};

export const TableEmptyState = ({ resourceName }: { resourceName: string }) => (
  <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
    <SpaceBetween size='xxs'>
      <Box>
        <b>{i18n.t(`No ${resourceName.toLowerCase()}`)}</b>
        <Box variant='p' color='inherit'>
          {i18n.t(`No ${resourceName.toLowerCase()} to display.`)}
        </Box>
      </Box>
    </SpaceBetween>
  </Box>
);

export const onClearTableFilterClick = (actions: CollectionActions<unknown> | undefined) => {
  return () => {
    actions?.setPropertyFiltering({ tokens: [], operation: 'and' });
  };
};

export const onClearTableTextFilterClick = (actions: CollectionActions<unknown> | undefined) => {
  return () => {
    actions?.setFiltering('');
  };
};

export const TableNoMatchState = (props: {
  onClearFilterClick: CancelableEventHandler<ButtonProps.ClickDetail>;
  buttonText?: string;
}): JSX.Element => (
  <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
    <SpaceBetween size='xxs'>
      <Box>
        <b>{i18n.t('No matches')}</b>
        <Box variant='p' color='inherit'>
          {i18n.t("We can't find a match.")}
        </Box>
      </Box>
      <Button onClick={props.onClearFilterClick}>
        {props.buttonText ? i18n.t(props.buttonText) : i18n.t('Clear filter')}
      </Button>
    </SpaceBetween>
  </Box>
);

export const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

/**
 * @param selectedCount count of selected items in the table
 * @param totalCount  count of fetched items for the table
 * @param isOpenEnded true if table has an unfetched page, false otherwise
 * @returns Table counter string in the (selectedCount/totalCount+) format
 */
export const getTableCounter = (selectedCount: number, totalCount: number, isOpenEnded: boolean | undefined) => {
  const totalString = `${totalCount}${isOpenEnded ? '+' : ''}`;
  return selectedCount ? `(${selectedCount}/${totalString})` : `(${totalString})`;
};

export const generateLink = (pathname: string, label: string, external = false) => {
  const linkProps = external ? { external: true } : { target: '_blank' };
  return (
    <Link href={pathname} {...linkProps}>
      {label}
    </Link>
  );
};

export const generateInternalHeaderLink = (pathname: string, label: string, counter?: string) => {
  return (
    <Header counter={counter}>
      <Link href={pathname} target='_blank' fontSize='heading-m'>
        {label}
      </Link>
    </Header>
  );
};

export const generateRiskStatusDisplay = (status: string) => {
  const statusIsAcknowledged = status === RiskStatus.ACKNOWLEDGED;
  const indicatorType = statusIsAcknowledged ? 'success' : 'warning';
  const indicatorOverrideColor = statusIsAcknowledged ? undefined : 'grey';
  return (
    <StatusIndicator type={indicatorType} colorOverride={indicatorOverrideColor}>
      {status}
    </StatusIndicator>
  );
};

const generateListFromMap = (map: Map<string, string[]>) => {
  const listItems = new Array<JSX.Element>();
  for (const [key, items] of map.entries()) {
    listItems.push(
      <li key={key}>
        <strong>{key}</strong> :
        <ul>
          {items.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </li>
    );
  }
  return listItems;
};

export const batchErrorAlertContent = (errors: Map<string, string[]>, customErrorMessage: string) => {
  return (
    <>
      {customErrorMessage}
      <ExpandableSection headerText='Details'>
        <ul>{generateListFromMap(errors)}</ul>
      </ExpandableSection>
    </>
  );
};

export const ValueWithLabel = ({ label, children }: { label: string; children: React.ReactNode }) => (
  <Box>
    <SpaceBetween size='xs'>
      <Box variant='awsui-key-label'>{label}</Box>
      <Box>{children}</Box>
    </SpaceBetween>
  </Box>
);
