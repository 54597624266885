import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { createContext } from 'react';
import { allSelectOption, riskDefaultDisplayOption } from '../table-definitions/filter-menu';

export interface SelectedRisk {
  readonly riskId: string;
  /** Used for error handling */
  readonly resourceId: string;
}

export interface RisksContextProps {
  updateRisks: (status: string, selectedRisks: SelectedRisk[]) => void;
  riskStatusFilterValue: OptionDefinition;
  ruleFilterValue: OptionDefinition;
  resourceTypeFilterValue: OptionDefinition;
  riskDisplayControlValue: string;
  filterRisks: (
    statusValue: OptionDefinition,
    ruleValue: OptionDefinition,
    resourceTypeValue: OptionDefinition
  ) => void;
  filterRiskStatus: (option: OptionDefinition) => void;
  filterRule: (option: OptionDefinition) => void;
  filterResourceType: (option: OptionDefinition) => void;
  updateRiskDisplay: (value: string) => void;
  resetAllRiskFilters: () => void;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const RisksContext = createContext<RisksContextProps>({
  updateRisks: () => {},
  resetAllRiskFilters: () => {},
  riskStatusFilterValue: allSelectOption,
  ruleFilterValue: allSelectOption,
  resourceTypeFilterValue: allSelectOption,
  riskDisplayControlValue: riskDefaultDisplayOption.id,
  filterRisks: () => {},
  filterRiskStatus: () => {},
  filterRule: () => {},
  filterResourceType: () => {},
  updateRiskDisplay: () => {}
});
