import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnLayout, Container, Header, Box, Spinner, Link } from '@amzn/awsui-components-react';

import useRuleDetails from './react-query/useRuleDetails';
import { useTranslation } from 'react-i18next';
import { NotificationsContext } from '../../common/Notifications';
import { RuleStructure } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import { bugReportURL } from '../../common/ticketing';
import { generateLink, ValueWithLabel } from '../../common/common-components';

const ALLOWED_DOMAINS = ['amazon.com'];

const ruleHelp = (initialMessage: string, t: (str: string) => string) => {
  return (
    <Box>
      {initialMessage + t(' Please contact the rule owner for help, or ')}
      <Link href={bugReportURL.href}>{t('cut us a ticket.')}</Link>
    </Box>
  );
};

const getRuleDocumentationCell = (rule: RuleStructure, t: (str: string) => string) => {
  let documentationContent;

  try {
    const url = new URL(rule.ruleDocumentation);

    if (ALLOWED_DOMAINS.some((domain: string) => url.hostname.endsWith(domain))) {
      documentationContent = generateLink(url.href, url.href, true);
    } else {
      documentationContent = ruleHelp(t('Documentation URL points to a disallowed domain.'), t);
    }
  } catch (e) {
    documentationContent = ruleHelp(t('Unable to parse documentation URL.'), t);
  }

  return <ValueWithLabel label={t('Documentation')}>{documentationContent}</ValueWithLabel>;
};

const RuleDetails = () => {
  const { t } = useTranslation();
  const notificationsControls = useContext(NotificationsContext);

  const params = useParams();

  const ruleId = String(params.ruleId);

  const { isLoading, data, isError } = useRuleDetails(ruleId, (err) =>
    notificationsControls.addAWSErrorNotification(err)
  );

  useEffect(() => {
    return () => {
      notificationsControls.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spinner size='large' data-testid='ruleDetailSpinner' />;
  }

  const ruleDetails = data?.rule;

  return (
    <>
      {!isError && ruleDetails && (
        <Container header={<Header variant='h2'>Rule Details</Header>}>
          <ColumnLayout columns={3} borders='vertical'>
            <ValueWithLabel label={t('Rule Name')}>{ruleDetails.name}</ValueWithLabel>
            <ValueWithLabel label={t('Rule ID')}>{ruleDetails.ruleId}</ValueWithLabel>
            <ValueWithLabel label={t('Description')}>{ruleDetails.description}</ValueWithLabel>
            <ValueWithLabel label={t('Severity')}>{ruleDetails.severity}</ValueWithLabel>
            {getRuleDocumentationCell(ruleDetails, t)}
          </ColumnLayout>
        </Container>
      )}
    </>
  );
};

export default RuleDetails;
