import { createContext, useContext } from 'react';
import { ChangeGuardianApprovalService, ClientConfig } from '@amzn/change-guardian-approval-service-type-script-client';
import { AWSError, Request } from 'aws-sdk';
import SentrySSOHandler from './SentrySSOHandler';

/**
 * Create a client for the Change Guardian Approval Service
 * @param stage Stage string for selecting the endpoint
 * @returns Returns a client object with authentication disabled
 */
export const getClient = (stage: string): ChangeGuardianApprovalService => {
  const client = new ChangeGuardianApprovalService({
    endpoint:
      stage === 'dev'
        ? process.env.REACT_APP_CGAS_DEV_API_ENDPOINT
        : ClientConfig[stage as keyof typeof ClientConfig].global.httpEndpoint.url,
    region: 'global',
    maxRetries: 0,
    httpOptions: {
      xhrWithCredentials: true
    },
    credentials: {
      accessKeyId: 'unused',
      secretAccessKey: 'unused'
    }
  });

  const sentrySSOHandler = SentrySSOHandler.instance;
  let csrfToken = '';

  client.setupRequestListeners = (request: Request<ChangeGuardianApprovalService, AWSError>) => {
    request.on('build', (req) => {
      if (csrfToken) {
        // Attach the prior csrf token
        req.httpRequest.headers['anti-csrftoken-a2z'] = csrfToken;
      }
      // Request a new token with each request
      req.httpRequest.headers['anti-csrftoken-a2z-request'] = String(true);
    });

    request.on('complete', (response) => {
      // Update the csrf token with the new one after each requesst
      if ('anti-csrftoken-a2z' in response.httpResponse.headers) {
        csrfToken = response.httpResponse.headers['anti-csrftoken-a2z'];
      }
    });

    // If the request failed, the error might be related to authentication.
    // sentrySSOHandler makes login requests and if needed redirects the user to Midway.
    request.on('error', () => {
      if (!sentrySSOHandler.hasCurrentAuth(request)) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        sentrySSOHandler.initializeAuth(request);
      }
    });

    /**
     * Workaround to remove AWS authorization from the client.
     *
     * Removes the "Authorization" header attached with the AWS.Signers.V4.
     * See: https://tiny.amazon.com/a1vnhd3v/codeamazpackNodeblobe99eawss
     *
     * We are sending cookies with xhrWithCredentials for auth.
     * "Authorization" header has to be removed because Midway is configured to
     * passthrough when that header is in the request.
     * See: https://tiny.amazon.com/nn5d006v/codeamazpackMidwblob640aconf
     */
    request.on('sign', (req) => {
      delete req.httpRequest.headers.Authorization;
    });
  };

  return client;
};

export function defaultQueryRetry(failureCount: number, error: AWSError) {
  if (error.retryable && failureCount < 2) {
    return true;
  }
  return false;
}

export const ChangeGuardianClientContext = createContext<ChangeGuardianApprovalService | undefined>(undefined);

/**
 * Helper hook to allow callers to use the context without worrying about undefined.
 */
export function useChangeGuardianClient() {
  const context = useContext(ChangeGuardianClientContext);
  if (!context) {
    throw new Error('useChangeGuardianClient must be used with a valid client');
  }
  return context;
}
