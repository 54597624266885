import ChangeGuardianApprovalService, {
  BatchUpdateRiskStatusInput,
  BatchUpdateRiskStatusOutput,
  UpdateRiskStatusEntry,
  UpdateRiskStatusFailureEntry,
  UpdateRiskStatusFailureList,
  UpdateRiskStatusList
} from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import { AWSError } from 'aws-sdk';
import { Promise } from 'bluebird';
import { useMutation } from 'react-query';
import { defaultQueryRetry, useChangeGuardianClient } from '../../../client/ChangeGuardianClientProvider';
import { awsErrorToErrorNotificationMessage } from '../../../common/Notifications';

const API_MAX_BATCH_SIZE = 100;
const BATCH_CONCURRENCY = 8;

/**
 * An internal type that has extra fields for error handling purposes
 */
interface ExtendedRiskStatusEntry extends UpdateRiskStatusEntry {
  readonly resourceId: string;
}

/**
 * An internal type that has extra fields for error handling purposes
 */
interface ExtendedUpdateRiskStatusInput extends BatchUpdateRiskStatusInput {
  readonly updateRiskStatusList: ExtendedRiskStatusEntry[];
}

/**
 * An internal type that has extra fields for error handling purposes
 */
interface ExtendedRiskStatusFailureEntry extends UpdateRiskStatusFailureEntry {
  readonly resourceId?: string;
}

export interface ExtendedUpdateRiskStatusOutput extends BatchUpdateRiskStatusOutput {
  readonly failures: ExtendedRiskStatusFailureEntry[];
}

const splitRiskList = (args: ExtendedUpdateRiskStatusInput): BatchUpdateRiskStatusInput[] => {
  const riskArrayChunks = new Array<UpdateRiskStatusList>();
  for (let i = 0; i < args.updateRiskStatusList.length; i += API_MAX_BATCH_SIZE) {
    riskArrayChunks.push(args.updateRiskStatusList.slice(i, i + API_MAX_BATCH_SIZE));
  }
  return riskArrayChunks.map((chunk) => {
    return {
      reviewId: args.reviewId,
      // Extract specific API fields from extended input
      updateRiskStatusList: chunk.map(({ riskId, status }) => ({ riskId, status }))
    };
  });
};

const batchUpdateRisksStatus = async (
  client: ChangeGuardianApprovalService,
  riskBatch: BatchUpdateRiskStatusInput
): Promise<BatchUpdateRiskStatusOutput> => {
  try {
    return await client.batchUpdateRiskStatus(riskBatch).promise();
  } catch (err) {
    const errorMessage = awsErrorToErrorNotificationMessage(err as AWSError);
    const updateErrors: UpdateRiskStatusFailureList = riskBatch.updateRiskStatusList.map((risk) => {
      return { ...risk, errorMessage };
    });
    return { successes: [], failures: updateErrors };
  }
};

export default (
  onSuccess?: (data: ExtendedUpdateRiskStatusOutput, variables: ExtendedUpdateRiskStatusInput) => void,
  onError?: (error: AWSError) => void
) => {
  const client = useChangeGuardianClient();
  return useMutation<ExtendedUpdateRiskStatusOutput, AWSError, ExtendedUpdateRiskStatusInput>(
    async (args) => {
      const updateRiskStatusBatches = splitRiskList(args);
      const batchUpdateResult = await Promise.map(
        updateRiskStatusBatches,
        (batch) => batchUpdateRisksStatus(client, batch),
        { concurrency: BATCH_CONCURRENCY }
      );
      return {
        successes: batchUpdateResult.flatMap((batch) => batch.successes),
        failures: batchUpdateResult
          .flatMap((batch) => batch.failures)
          .map((failure) => ({
            ...failure,
            // Since users are not shown risk IDs, add resource IDs to all failures, if found in input
            resourceId: args.updateRiskStatusList.find(({ riskId }) => riskId === failure.riskId)?.resourceId
          }))
      };
    },
    {
      onSuccess,
      onError,
      retry: defaultQueryRetry
    }
  );
};
