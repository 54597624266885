import { useQuery } from 'react-query';
import { useChangeGuardianClient, defaultQueryRetry } from '../../../client/ChangeGuardianClientProvider';
import { AWSError } from 'aws-sdk';

export default (reviewId: string, resourceId: string, onError?: (err: AWSError) => void) => {
  const client = useChangeGuardianClient();
  return useQuery(
    ['getResourceRevision', reviewId, resourceId],
    () => {
      return client.getResourceRevision({ resourceId, reviewId }).promise();
    },
    {
      onError,
      retry: defaultQueryRetry
    }
  );
};
