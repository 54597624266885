import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Cards,
  CardsProps,
  Header,
  Pagination,
  SpaceBetween,
  NonCancelableCustomEvent
} from '@amzn/awsui-components-react';
import { someRiskStatus } from '../table-definitions/risk-table';
import { RiskColumnDefinitionData, isRiskAcknowledgementDisabled } from '../table-definitions/common';
import {
  PAGE_SIZE,
  RISK_CARDS_ID,
  RiskCardDefinitionData,
  aggregateRisksByRule,
  allRisksInCardSelected,
  getCardDefinition,
  getUpdatedRiskSelection,
  riskCountByRuleMap
} from '../table-definitions/risk-cards';
import {
  CLEAR_ALL_BUTTON_TEXT,
  TableEmptyState,
  TableNoMatchState,
  getTableCounter,
  paginationLabels
} from '../../../common/common-components';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useTranslation } from 'react-i18next';
import { RisksContext } from '../context/RisksContext';
import { SplitPanelContext } from '../../layout-housing/SplitPanel';
import { RiskStatus } from '../../../common/RiskStatus';
import { CardRiskSelectionContext } from '../context/CardRiskSelectionContext';

interface RiskCardsProps {
  riskItems: RiskColumnDefinitionData[];
  isLoadingRisks: boolean;
  hasMoreRisks: boolean;
  isMutating: boolean;
  totalRiskCount: number;
}

const RiskCards = ({ riskItems, isLoadingRisks, hasMoreRisks, isMutating, totalRiskCount }: RiskCardsProps) => {
  const { t } = useTranslation();
  const risksControls = useContext(RisksContext);
  const splitPanelControls = useContext(SplitPanelContext);
  const [cardItems, setCardItems] = useState<readonly RiskCardDefinitionData[]>([]);
  const [cardsSelectedItems, setCardsSelectedItems] = useState<RiskCardDefinitionData[]>([]);
  const [riskSelectedItems, setRisksSelectedItems] = useState<RiskColumnDefinitionData[]>([]);

  const clearAllReviewFilters = () => {
    return () => {
      risksControls.resetAllRiskFilters();
    };
  };

  const { items, collectionProps, paginationProps } = useCollection(cardItems, {
    pagination: { pageSize: PAGE_SIZE },
    selection: { keepSelection: true, trackBy: 'ruleId' },
    filtering: {
      empty: totalRiskCount ? (
        <TableNoMatchState onClearFilterClick={clearAllReviewFilters} buttonText={CLEAR_ALL_BUTTON_TEXT} />
      ) : (
        <TableEmptyState resourceName='Risks' />
      )
    }
  });

  const cardSelectionProviderValue = {
    riskSelectedItems,
    updateRiskSelectedItems: (riskSelection: RiskColumnDefinitionData[]) => {
      setRisksSelectedItems(riskSelection);
    }
  };

  const onRiskActionButtonClick = (status: string) => {
    return () => {
      risksControls.updateRisks(
        status,
        riskSelectedItems.map(({ riskId, resourceId }) => ({ riskId, resourceId }))
      );
    };
  };

  const onCardSelectionChange = ({
    detail
  }: NonCancelableCustomEvent<CardsProps.SelectionChangeDetail<RiskCardDefinitionData>>) => {
    const updatedRiskSelectedItems = getUpdatedRiskSelection(
      cardsSelectedItems,
      riskSelectedItems,
      detail.selectedItems
    );
    setRisksSelectedItems(updatedRiskSelectedItems);
    setCardsSelectedItems(detail.selectedItems);
  };

  const populateCards = () => {
    setCardsSelectedItems([]);
    setRisksSelectedItems([]);
    setCardItems(aggregateRisksByRule(riskItems));
  };

  useEffect(() => {
    populateCards();
    // equivalent of willUnmount; reset split panel
    return () => {
      splitPanelControls.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskItems]);

  useEffect(() => {
    const selectedRisksByRuleCounter = riskCountByRuleMap(riskSelectedItems);
    const newCardsSelection = [];
    for (const ruleCard of cardItems) {
      if (allRisksInCardSelected(selectedRisksByRuleCounter, ruleCard)) {
        newCardsSelection.push(ruleCard);
      }
    }
    setCardsSelectedItems(newCardsSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskSelectedItems]);

  return (
    <CardRiskSelectionContext.Provider value={cardSelectionProviderValue}>
      <Cards
        {...collectionProps}
        onSelectionChange={onCardSelectionChange}
        selectedItems={cardsSelectedItems}
        cardDefinition={getCardDefinition(riskSelectedItems, cardItems, hasMoreRisks, isLoadingRisks)}
        cardsPerRow={[{ cards: 1 }]}
        items={items}
        isItemDisabled={isRiskAcknowledgementDisabled}
        loading={isLoadingRisks || hasMoreRisks || isMutating}
        selectionType='multi'
        empty={<TableNoMatchState onClearFilterClick={clearAllReviewFilters()} buttonText={CLEAR_ALL_BUTTON_TEXT} />}
        header={
          <Header
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  variant='normal'
                  disabled={
                    riskSelectedItems.length === 0 || someRiskStatus(riskSelectedItems, RiskStatus.UNACKNOWLEDGED)
                  }
                  onClick={onRiskActionButtonClick(RiskStatus.UNACKNOWLEDGED)}
                  loading={isMutating}
                >
                  {t('Unapprove')}
                </Button>
                <Button
                  variant='primary'
                  disabled={
                    riskSelectedItems.length === 0 || someRiskStatus(riskSelectedItems, RiskStatus.ACKNOWLEDGED)
                  }
                  onClick={onRiskActionButtonClick(RiskStatus.ACKNOWLEDGED)}
                  loading={isMutating}
                >
                  {t('Approve')}
                </Button>
              </SpaceBetween>
            }
            counter={getTableCounter(riskSelectedItems.length, riskItems.length, hasMoreRisks)}
          >
            {t('Risks')}
          </Header>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} openEnd={hasMoreRisks} />}
        data-testid={RISK_CARDS_ID}
      />
    </CardRiskSelectionContext.Provider>
  );
};

export default RiskCards;
