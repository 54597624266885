import { SplitPanelContextProps } from '../../layout-housing/SplitPanel';
import {
  RiskColumnDefinitionData,
  getCreationDateColumnDefinition,
  getExpandButtonColumnDefinition,
  getResourceIdColumnDefinition,
  getSeverityColumnDefinition,
  getStatusColumnDefinition
} from './common';

export const columnDefinitions = (reviewId: string, splitPanelContext: SplitPanelContextProps) => [
  getExpandButtonColumnDefinition(reviewId, splitPanelContext),
  getResourceIdColumnDefinition(reviewId),
  getSeverityColumnDefinition(),
  getStatusColumnDefinition(),
  getCreationDateColumnDefinition()
];

export interface RiskCardTableProps {
  readonly riskItems: RiskColumnDefinitionData[];
  ruleId: string;
  isLoading: boolean;
}
