import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getDefaultRiskStatus } from './filter-menu/RiskFilterMenu';
import { Alert, Box, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import useRisks from './react-query/useRisks';
import { useParams } from 'react-router';
import ReviewTabs from './ReviewTabs';
import { NotificationsContext } from '../../common/Notifications';
import { toResourceRevisionsItems, toRiskTableItems } from './react-query/translation';
import { toFilteredRiskTableItems, someRiskStatus } from './table-definitions/risk-table';
import { RiskColumnDefinitionData, getReviewBadges } from './table-definitions/common';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { RisksContext, RisksContextProps } from './context/RisksContext';
import { ResourceRevisionList } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import useBatchUpdateRiskStatusMutation from './react-query/useBatchUpdateRiskStatusMutation';
import { useSearchParams } from 'react-router-dom';
import { allSelectOption, SearchParams, riskDefaultDisplayOption } from './table-definitions/filter-menu';
import useResourceRevisions from './react-query/useResourceRevisions';
import { RiskStatus } from '../../common/RiskStatus';
import { toFilteredResourceRevisionTableItems } from './table-definitions/resource-revision-table';
import { ResourceRevisionsContext } from './context/ResourceRevisionsContext';
import { getSeverityEnum, Severity } from './risks/Severity';

type FilterOption = {
  optionValue?: string;
  searchParamName: string;
};

interface ReviewProps {
  activeTabId: string;
}

const Review = ({ activeTabId }: ReviewProps) => {
  const { reviewId } = useParams();
  const notificationsControls = useContext(NotificationsContext);
  const [riskTableItems, setRiskTableItems] = useState([] as RiskColumnDefinitionData[]);
  const [resourceRevisionTableItems, setResourceRevisionTableItems] = useState([] as ResourceRevisionList);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [riskStatusFilterValue, setRiskStatusFilterValue] = useState<OptionDefinition>(allSelectOption);
  const [ruleFilterValue, setRuleFilterValue] = useState<OptionDefinition>(allSelectOption);
  const [resourceTypeFilterValue, setResourceTypeFilterValue] = useState<OptionDefinition>(allSelectOption);
  const [riskDisplayControlValue, setRiskDisplayControlValue] = useState<string>(riskDefaultDisplayOption.id);
  const [operationFilterValue, setOperationFilterValue] = useState<OptionDefinition>(allSelectOption);

  /**
   * Update the query search param using the filter option.
   *
   * @param filter FilterOption object
   */
  const updateSearchParams = (filter: FilterOption) => {
    if (filter.optionValue) {
      searchParams.set(filter.searchParamName, filter.optionValue);
      setSearchParams(searchParams);
    }
  };

  const {
    data: riskData,
    hasNextPage: hasMoreRisks,
    isLoading: isLoadingRisks,
    refetch: refetchRisks,
    fetchNextPage: fetchMoreRisks
  } = useRisks(
    String(reviewId),
    1000,
    undefined,
    () => {
      hasMoreRisks !== false && fetchMoreRisks();
    },
    (err) => notificationsControls.addAWSErrorNotification(err, 'Failed to load risks')
  );
  const {
    data: resourceRevisionData,
    isLoading: isLoadingResourceRevisions,
    hasNextPage: hasMoreResourceRevisions,
    fetchNextPage: fetchMoreResourceRevisions
  } = useResourceRevisions(
    String(reviewId),
    undefined,
    () => {
      hasMoreResourceRevisions !== false && fetchMoreResourceRevisions();
    },
    (err) => notificationsControls.addAWSErrorNotification(err, 'Failed to load resource revisions')
  );

  const memoizedRiskItems = useMemo(() => {
    const riskItems = toRiskTableItems(riskData);
    setRiskTableItems(riskItems);
    return riskItems;
  }, [riskData]);

  const memoizedResourceRevisionItems = useMemo(() => {
    const resourceRevisions = toResourceRevisionsItems(resourceRevisionData);
    setResourceRevisionTableItems(resourceRevisions);
    return resourceRevisions;
  }, [resourceRevisionData]);

  const batchUpdateRiskStatusMutation = useBatchUpdateRiskStatusMutation(
    (data) => {
      if (data.successes.length) {
        notificationsControls.addSuccessNotification(
          `Successfully updated status for ${data.successes.length} risk(s)`
        );
      }
      // Because this is a batch operation there can be some error under "failures" in the response
      // even if the call succeeds
      if (data.failures.length) {
        const batchUpdateUniqueErrorsMap = data.failures.reduce((errors, item) => {
          if (item.resourceId) {
            return errors.set(
              item.errorMessage,
              (errors.get(item.errorMessage) || new Array<string>()).concat(item.resourceId)
            );
          }
          console.warn(`Unexpected risk ID received from backend: ${item.riskId!}`);
          return errors;
        }, new Map<string, string[]>());
        notificationsControls.addBatchErrorNotification(
          batchUpdateUniqueErrorsMap,
          `Failed to update ${data.failures.length} risk(s)`
        );
      }
      // Error of refetch is handled in the useRisk hook
      refetchRisks().finally(() => setIsMutating(false));
    },
    (err) => {
      notificationsControls.addAWSErrorNotification(err, 'Failed to update risks');
      setIsMutating(false);
    }
  );

  const filterRisks = (
    statusValue: OptionDefinition,
    ruleValue: OptionDefinition,
    resourceTypeValue: OptionDefinition
  ) => {
    setRiskStatusFilterValue(statusValue);
    setRuleFilterValue(ruleValue);
    setResourceTypeFilterValue(resourceTypeValue);

    updateSearchParams({ optionValue: statusValue.value, searchParamName: SearchParams.RISK_STATUS });
    updateSearchParams({ optionValue: ruleValue.value, searchParamName: SearchParams.RULE });
    updateSearchParams({ optionValue: resourceTypeValue.value, searchParamName: SearchParams.RESOURCE_TYPE });

    setIsFiltering(true);
    const filteredRisks = toFilteredRiskTableItems(
      memoizedRiskItems,
      memoizedResourceRevisionItems,
      statusValue,
      ruleValue,
      resourceTypeValue
    );
    setRiskTableItems(filteredRisks);
    setIsFiltering(false);
  };

  const filterResourceRevisions = (operationValue: OptionDefinition, resourceTypeValue: OptionDefinition) => {
    setOperationFilterValue(operationValue);
    setResourceTypeFilterValue(resourceTypeValue);
    updateSearchParams({ optionValue: operationValue.value, searchParamName: SearchParams.OPERATION });
    updateSearchParams({ optionValue: resourceTypeValue.value, searchParamName: SearchParams.RESOURCE_TYPE });

    setIsFiltering(true);
    const filteredResourceRevisions = toFilteredResourceRevisionTableItems(
      memoizedResourceRevisionItems,
      operationValue,
      resourceTypeValue
    );
    setResourceRevisionTableItems(filteredResourceRevisions);
    setIsFiltering(false);
  };

  const riskContextProviderValue: RisksContextProps = {
    updateRisks: (status, selectedRisks) => {
      setIsMutating(true);
      batchUpdateRiskStatusMutation.mutate({
        reviewId: String(reviewId),
        updateRiskStatusList: selectedRisks.map((risk) => ({ ...risk, status }))
      });
    },
    resetAllRiskFilters: () => {
      searchParams.delete(SearchParams.RISK_STATUS);
      searchParams.delete(SearchParams.RULE);
      searchParams.delete(SearchParams.RESOURCE_TYPE);
      setSearchParams(searchParams);

      const riskStatus = getDefaultRiskStatus(undefined, someRiskStatus(memoizedRiskItems, RiskStatus.UNACKNOWLEDGED));
      setRiskTableItems(memoizedRiskItems);
      filterRisks(riskStatus, allSelectOption, allSelectOption);
    },
    riskStatusFilterValue,
    ruleFilterValue,
    resourceTypeFilterValue,
    riskDisplayControlValue,
    filterRisks,
    filterRiskStatus: (option: OptionDefinition) => {
      setRiskStatusFilterValue(option);
      filterRisks(option, ruleFilterValue, resourceTypeFilterValue);
    },
    filterRule: (option: OptionDefinition) => {
      setRuleFilterValue(option);
      filterRisks(riskStatusFilterValue, option, resourceTypeFilterValue);
    },
    filterResourceType: (option: OptionDefinition) => {
      setResourceTypeFilterValue(option);
      filterRisks(riskStatusFilterValue, ruleFilterValue, option);
    },
    updateRiskDisplay: (value: string) => {
      setRiskDisplayControlValue(value);
      updateSearchParams({ optionValue: value, searchParamName: SearchParams.RISK_DISPLAY });
    }
  };

  const resourceRevisionsContextProviderValue = {
    resetAllResourceRevisionFilters: () => {
      searchParams.delete(SearchParams.OPERATION);
      searchParams.delete(SearchParams.RESOURCE_TYPE);
      setSearchParams(searchParams);
      setResourceRevisionTableItems(memoizedResourceRevisionItems);
      filterResourceRevisions(allSelectOption, allSelectOption);
    },
    operationFilterValue,
    resourceTypeFilterValue,
    filterResourceRevisions,
    filterOperation: (option: OptionDefinition) => {
      setOperationFilterValue(option);
      filterResourceRevisions(option, resourceTypeFilterValue);
    },
    filterResourceType: (option: OptionDefinition) => {
      setResourceTypeFilterValue(option);
      filterResourceRevisions(operationFilterValue, option);
    }
  };

  const hasCriticalRisks = (risks: RiskColumnDefinitionData[]) => {
    return risks.some((risk) => getSeverityEnum(risk.severity) === Severity.CRITICAL);
  };

  useEffect(() => {
    return () => {
      notificationsControls.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ResourceRevisionsContext.Provider value={resourceRevisionsContextProviderValue}>
      <RisksContext.Provider value={riskContextProviderValue}>
        <Box margin='s'>
          <SpaceBetween size='s'>
            <Container header={<Header variant='h2'>Change Guardian Review</Header>}>
              {getReviewBadges(
                memoizedRiskItems,
                isLoadingRisks,
                memoizedResourceRevisionItems,
                isLoadingResourceRevisions
              )}
            </Container>
            {hasCriticalRisks(memoizedRiskItems) && (
              <Alert statusIconAriaLabel='Warning' type='warning'>
                This review contains critical risks that cannot be acknowledged.
              </Alert>
            )}
            <ReviewTabs
              activeTabId={activeTabId}
              riskItems={memoizedRiskItems}
              riskTableItems={riskTableItems}
              riskDisplayOption={riskDisplayControlValue}
              resourceRevisionItems={memoizedResourceRevisionItems}
              resourceRevisionTableItems={resourceRevisionTableItems}
              isLoadingRisks={isLoadingRisks}
              hasMoreRisks={hasMoreRisks || false}
              isLoadingResourceRevisions={isLoadingResourceRevisions}
              hasMoreResourceRevisions={hasMoreResourceRevisions || false}
              isMutating={isMutating}
              isFiltering={isFiltering}
            />
          </SpaceBetween>
        </Box>
      </RisksContext.Provider>
    </ResourceRevisionsContext.Provider>
  );
};

export default Review;
