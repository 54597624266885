import {
  getRuleColumnDefinition,
  getStatusColumnDefinition,
  getSeverityColumnDefinition,
  getDescriptionColumnDefinition
} from '../reviews/table-definitions/common';

export const ColumnDefinitions = () => [
  getRuleColumnDefinition(),
  getSeverityColumnDefinition(),
  getStatusColumnDefinition(),
  getDescriptionColumnDefinition()
];
